:root {
    --finnish-blue: #002f6c;
    --finnish-red: #C8102E;
    --finnish-yellow: #FFC72C;
    --border-rad: 10px;
}

body {
    background: #fafafa;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    max-width: 1400px;
}

.row+.row {
    margin-top: 5px;
}

.control-group {
    display: inline-block;
    vertical-align: top;
}

.card-flex {
    /* We first create a flex layout context */
    display: flex;
    /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
    flex-flow: row wrap;
    /* Then we define how is distributed the remaining space */
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    list-style: none;
    /* background:var(--finnish-blue); */
}

.l-stretch {
    flex: 2 2 200px;
    max-width: 600px;
    min-width: 600px;
}

.r-stretch {
    flex: 1 1 100px;
    max-width: 600px;
    min-width: 600px;
}

.word-card {
    /* border-radius:10px; */
    font-family: Comfortaa;
    margin-top: 10px;
    /* margin-bottom: 10px; */
    /* max-width: 600px; */
    min-width: 100px;
    word-break: break-all;
    white-space: normal;
}

.rcard {
    /* font-size: 1.5em; */
    /* min-width: 600px; */
    font-weight: 400;
    height: 100px;
    padding: 0;
    /* margin-left: 5px; */
    border-radius: 0 var(--border-rad) var(--border-rad) 0;
}

.lcard {
    font-size: 100px;
    font-weight: 700;
    height: 320px;
    /* margin-left: -5px; */
    /* margin-right: 5px; */
    border-radius: var(--border-rad) 0 0 var(--border-rad);
}

.ltext {
    line-height: 320px;
    color: var(--finnish-blue);
}

.card-text {
    text-align: center;
    overflow: hidden;
}

.rtext {
    line-height: 100px;
}

.word-card.blue {
    background: var(--finnish-blue);
    color: #fff;
}

.word-card.red {
    background: var(--finnish-red);
    color: var(--finnish-yellow);
}

.word-card.yellow {
    background: var(--finnish-yellow);
    color: var(--finnish-red);
}

.card.gray {
    background: #fff;
    width: 1100px;
    /* display: inline; */
    margin: 100px auto;
}

.no-margin {
    margin: 0;
    padding: 0;
}

.rcard-image {
    width: 100px;
    border-radius: 0;
    /*4px 0 0 4px;*/
}

.input-row {
    padding-right: 10px;
    padding-left: 10px;
}

/* .input-card{
    height:100px;
    width: 1210px;
    margin: 5px auto;
    padding: 0 0 0 5px;
} */

.word-input-flex {
    /* flex: 0 1 1220px; */
    /* max-width: 1210px; */
    width: 100%;
    padding: 0 0 0 0;
    /* min-width: 600px; */
}

.word-input {
    /* display: inline-block; */
    color: black;
    /* min-width: 1000px; */
    /* box-sizing: border-box; */
    border: none;
    border-radius: var(--border-rad);
    /* margin: 10px auto; */
    font-size: 30pt;
    /* margin: 10px auto; */
    /* margin-left: 100; */
    /* display:inline-block; */
    /* padding:15px 10px; */
    /* font-size: 30px; */
    font-family: Comfortaa;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    width: 100%;
    padding: 20px;
    /* background-image: url("../public/flag-uk.svg");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: 99% 100%; */
}

.black-bg {
    background-color: rgba(0, 0, 0, 0.15);
    transition: background-color 3s cubic-bezier(.18, .89, .32, 1.28);
}

.red-bg {
    background-color: #C67B87;
}

.green-bg {
    background-color: #8BC98B;
}

@media only screen and (max-width: 1200px) {
    .lcard {
        height: 160px;
        border-radius: var(--border-rad);
    }

    .rcard {
        border-radius: var(--border-rad);
    }

    .rcard-image {
        border-radius: var(--border-rad) 0 0 var(--border-rad);
    }

    .ltext {
        line-height: 160px;
    }
}

@media only screen and (max-height: 720px) and (max-width:1200px) {
    .word-card.blue {
        display: none;
    }

    .word-card.yellow {
        display: none;
    }
}

.no-l-padding {
    padding-left: 0;
    padding-right: 5px;
}

.no-r-padding {
    padding-left: 5px;
    padding-right: 0;
}

@media only screen and (max-width:1200px) {
    .no-l-padding {
        padding-right: 0;
    }

    .no-r-padding {
        padding-left: 0;
    }
}

.about {
    margin-top: 30px;
    max-width: 800px;
    font-family: Roboto;
    background: white;
    color: var(--finnish-blue);
    font-size: large;
    padding: 30px;

}

/* span{overflow:hidden;} */

.wrongIndicator {
    width: 50px;
    margin: 0 0 0 -50px;
    float: right;
    /* display:inline-block; */
    /* position: absolute;
  z-index:1; */
}

.nav {
    flex-direction: row;
}

ul#nav li {
    display: inline;
}

::placeholder {
    overflow: visible;
}

.settings {
    /* width: 1210px; */
    background-color: #3b3434;
    border-radius: var(--border-rad);
    padding: 40px;
    font-family: Comfortaa;
    color: white;
    /* margin-top: 20px; */
    margin-bottom: 20px;
}

.active {
    color: red;
}

/* input[type=checkbox] {
    zoom: 2;
} */

/* input[type=text] {
    transform: scale(5);
} */

/* 
.center-div{
  width: 1010px;
} */